<template>
  <section>
    <b-table-simple 
      v-if="kitTemplates?.length && !isLoading"
      id="guides-table"
      responsive 
      borderless 
      class="table-eyecare mt-2"
    >
      <thead>
        <tr>
          <th>Cód. do Kit</th>
          <th>Data de criação</th>
          <th>Nome do kit</th>
          <th class="text-align-center">Qtde de produtos</th>
          <th>Produtos em estoque</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-for="(template, index) in kitTemplates" :key="index">
        <tr>
          <td>
            <strong class="ml-2">{{ template?.code }}</strong>
          </td>
          <td>
            {{ parseDate(template?.created_at) }}
          </td>
          <td>
            {{ template?.name }}
          </td>
          <td class="text-align-center">
            {{ template?.products?.length }}
          </td>
          <td>
            {{
              template?.products?.find(product => !product?.product_balance)
                ? 'Produto(s) em falta'
                : 'Sim'
            }}
          </td>
          <td class="no-print text-align-center">
            <b-button 
              v-b-toggle="`collapse-${template?.id}-${index}`" 
              class="toggle-btn" 
              @click="rotateIcon"
            >
              <ChevronDown class="icon" />
            </b-button>
            <div class="options">
              <MoreVertical class="more-icon" />
              <div class="menu">
                <b-button
                  variant="link"
                  @click="duplicateKit(template)"
                >
                  Duplicar kit
                </b-button>
                <b-button
                  variant="link"
                  @click="edit(template)"
                >
                  Editar
                </b-button>
                <b-button
                  variant="link remove mb-0"
                  @click="remove(template?.id)"
                >
                  Excluir
                </b-button>
              </div>
            </div>
          </td>
        </tr>
        <tr class="collapsable-row">
          <td colspan="12" class="p-0">
            <b-collapse :id="`collapse-${template?.id}-${index}`" class="collapse">
              <b-table-simple 
                class="subtable collapse-table" 
                responsive borderless fixed 
              >
                <thead>
                  <th>Código</th>
                  <th>Produto</th>
                  <th>Unidade de medida</th>
                  <th>Quantidade</th>
                </thead>
                <tbody>
                  <tr v-for="(product, i) in template?.products" :key="i">
                    <td>
                      <strong class="ml-2">{{ product?.barcode }}</strong>
                    </td>
                    <td>
                      {{ product?.name }}
                      <Warning  
                        v-if="product?.product_balance?.balance < 0"
                        class="warning-icon danger" 
                        v-b-tooltip title="Produto em falta"
                      />
                    </td>
                    <td>{{ product?.measure_unit?.name }}</td>
                    <td >
                      <span :class="{'light-orange': product?.product_balance?.balance < product?.pivot?.quantity}">
                        {{ product?.pivot?.quantity }}
                      </span>
                      <Warning  
                        v-if="product?.product_balance?.balance < product?.pivot?.quantity"
                        class="warning-icon light-orange mb-1" 
                        v-b-tooltip title="Produto com quantidade menor em estoque" 
                      />
                    </td>
                  </tr>
                </tbody>
              </b-table-simple>
            </b-collapse>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
    <div v-if="isLoading || !kitTemplates?.length" class="text-align-center">
      <div v-if="isLoading" class="mt-5 mb-5">
        <b-skeleton v-for="n in 3" :key="n" height="50px" class="mb-2" />
      </div>
      <noFilterResults 
        v-else-if="!kitTemplates?.length"
        message="Os filtros aplicados não geraram nenhum resultado." 
      />
    </div>
  </section>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'kit-templates-table',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
    noFilterResults: () => import('@/components/General/noFilterResults.vue'),
    Warning: () => import('@/assets/icons/warning-2.svg'),
  },
  props: {
    kitTemplates: Array,
    isLoading: Boolean,
  },
  data () {
    return {
    }
  },
  methods: {
    parseNumberToMoney,
    rotateIcon($event) {
      const icon = $event.target.closest('button').querySelector('.icon')
      icon.classList.toggle('rotate')
    },
    parseDate(datetime) {
      return this.moment(datetime).format('DD/MM/YYYY')
    },
    duplicateKit (template) {
      this.$set(template, 'id', null)
      template.products.map(product => {
        this.$set(product, 'quantity', product?.pivot?.quantity )
        this.$set(product, 'kit_template_product_id', null)
        this.$set(product, 'product_id', product?.id)
      })
      this.$emit('duplicate', template)
    },
    edit (template) {
      template.products.map(product => {
        this.$set(product, 'quantity', product?.pivot?.quantity )
        this.$set(product, 'kit_template_product_id', product?.pivot?.id)
        this.$set(product, 'product_id', product?.id)
      })
      
      this.$emit('edit', template)
    },
    remove (templateId) {
      this.$emit('delete', templateId)
    },
    isBelowMinimum(product) {
      return product.product_balance && product.product_balance.balance < product.minimum_quantity;
    },
    isEqualMinimum(product) {
      return product.product_balance && product.product_balance.balance === product.minimum_quantity;
    }
  }
}
</script>

<style lang="scss" scoped>

@media(max-width: 1440px) {
  .table-eyecare th,td{
    font-size: 0.8rem !important;
  }
}

thead {
  background: var(--neutral-100) !important;
  th { padding: 15px 0 15px 10px !important; }
}
.subtable {
  border-radius: 8px;
  border: 1px dashed var(--neutral-300) !important;

  tr:not(:last-child) {
    border-bottom: 1px dashed var(--neutral-300) !important;
  }
}

tr {
  border: none !important;
  padding: 0px 25px !important;
}

tr:nth-child(even) {
  background: var(--neutral-000) !important;
}

td {
  vertical-align: middle !important;
  .blue-text {
    color: var(--blue-500);
  }

  .checkbox-td {
    display: flex;
    justify-content: center;
    .checkbox {
      margin-bottom: 20px !important;
      vertical-align: middle !important;
      display: flex;
    }
  }
}

.neutral-bg {
  background: var(--neutral-100) !important;
}

.collapsable-row {
  td {
    border: none !important;
    padding-left: 20px !important;

    .warning-icon {
      transform: scale(0.8);
    }

    .light-orange {
      fill: var(--light-orange-500);
      color: var(--light-orange-500);
    }

    .danger {
      fill: var(--states-red-soft);
    }
  }
}
.toggle-btn {
  background: var(--blue-100);
  border: none;
  padding: 5px !important;

  @media(min-width: 1440px) {
    margin-right: 50px;
  }

  @media(max-width: 1440px) {
    margin-right: 20px;
  }

  .icon {
    width: 25px;
    transition: all 500ms;
  }
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}

.options {
  cursor: pointer;
  width: 24px;
  height: 28px;
  position: relative;
  display: inline-block;

  .more-icon {
    width: 24px;
    height: 24px;
    stroke: var(--neutral-500);
    transition: all 0.5s;
    cursor: pointer;
  }

  .menu {
    width: 141px;
    position: absolute;
    top: 28px;
    right: 0;
    background: var(--neutral-000);
    border: 1px solid var(--neutral-200);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
      0px 4px 10px -8px rgba(12, 29, 89, 0.2);
    border-radius: 8px;
    padding: 0;
    opacity: 0;
    transition: all 0.5s;
    flex-direction: column;
    align-items: start;
    display: none;

    .mtb-12 {
      margin: 12px 0;
    }

    .btn {
      font-weight: 600;
      width: 100%;

      &.remove {
        color: var(--states-error);
      }

      &.btn-link {
        text-align: left;
        padding: 12px 18px !important;
      }
    }
  }

  &:hover {
    .more-icon {
      transform: rotate(90deg);
    }

    .menu {
      opacity: 1;
      display: flex;
      z-index: 10;
    }
  }
}
</style>